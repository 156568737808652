import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import ProductionSummary from "./scenes/production-summary";
import HarvestForecast from "./scenes/harvest-forecast";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import BaggingSummary from "./scenes/bagging-summary";
import BoxBillingSummary from "./scenes/box-billing-summary";
import WeekNumbers from "./scenes/week-numbers";
import CreateBagging from "./scenes/bagging-summary/create-bagging";
import EnterBunches from "./scenes/forms/enter-bunches";
import DeliveryReceipt from "./scenes/forms/delivery-receipt";
import EnterWeeklyBagging from "./scenes/forms/enter-weekly-bagging";
import ProductionReport from "./scenes/forms/production-report";
import DailyTimeRecord from "./scenes/forms/daily-time-record";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/bagging-summary" element={<BaggingSummary />} />
              <Route path="/create-bagging" element={<CreateBagging />} />              
              <Route path="/harvest-forecast" element={<HarvestForecast />} />
              <Route path="/production-summary" element={<ProductionSummary />} />
              <Route path="/box-billing-summary" element={<BoxBillingSummary />} />
              <Route path="/week-numbers" element={<WeekNumbers />} />
              <Route path="/forms/enter-bunches" element={<EnterBunches />} />
              <Route path="/forms/delivery-receipt" element={<DeliveryReceipt />} />
              <Route path="/forms/enter-weekly-bagging" element={<EnterWeeklyBagging />} />
              <Route path="/forms/production-report" element={<ProductionReport />} />
              <Route path="/forms/daily-time-record" element={<DailyTimeRecord />} />
              
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
