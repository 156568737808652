import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Field, Form, Formik, FormikProps } from 'formik';

import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";

import { arbList,phLineList,phLineNumberList, positionList } from "../../data/mockData";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import Header from "../../components/Header";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,   
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,   
  },  
}));



const DailyTimeRecord = () => {

  const [dt, setDt] = React.useState(new Date())
  const [tabValue, setTabValue] = React.useState(0)


  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const [phLineNo, setPhLineNo] = React.useState('');

  const [phLineNoLine, setPhLineNoLine] = React.useState('');

  const [position, setPosition] = React.useState('');

  const [phLine, setPhLine] = React.useState('');

  const [phLineSelectedAll, setPhLineSelectedAll] = React.useState([])

  const phLineChangeSelect = (event) => {
    setPhLine(event.target.value);
    console.log('phLineChangeSelect:', event.target.value);  
  };

  const phLineNoChangeSelect = (event) => {
    setPhLineNo(event.target.value);
    console.log('setPhLineNo:', event.target.value);  
    const data = phLineNumberList.find(({ id }) => id === event.target.value);
    console.log('setPhLineNo data:', JSON.stringify(data));  
    setPhLineNoLine(data.phline);
  };

  const positionChangeSelect = (event) => {
    setPosition(event.target.value);
    console.log('setPosition:', event.target.value);  
    //const data = positionList.find(({ id }) => id === event.target.value);  
  };


   let phNames = phLineList.length > 0
    	&& phLineList.map((item, i) => {
        //console.log('jona all id:' + item.id);
        //console.log('jona all name:' + item.name);
      return (    
        
        <MenuItem  key={i} value={item.id}>{item.name}</MenuItem >
      )
    }, this);


    let phLines = phLineNumberList.length > 0
    	&& phLineNumberList.map((item, i) => {
      return (    
        <MenuItem key={i} value={item.id}>{item.phno}</MenuItem>
      )
    }, this);


    let positionData = positionList.length > 0
    && positionList.map((item, i) => {
    return (    
      <MenuItem key={i} value={item.id}>{item.position}</MenuItem>
    )
  }, this);
   

 
    const handleFormChange = (event, index) => {
      /*
      let data = [...formFields];
      data[index][event.target.name] = event.target.value;
      //setFormFields(data);
      */
    }
  
    const submit = (e) => {
      e.preventDefault();
      //console.log(formFields)
    }
  
   

    const addPHLine = () => {
      console.log('me data phLine:', phLine)
      if(phLine)
      {
        const data = phLineList.find(({ id }) => id === phLine);
        const check = phLineSelectedAll.find(({ id }) => id === phLine);
        if(!check)
        {
          let object = {
            id: data.id,
            name: data.name
          }
      
          setPhLineSelectedAll([...phLineSelectedAll, object])
          /*
          itemsSelectedFields.push({
              id: data.id,
              name: data.name,
          })
          */
        }
        console.log('all index:', JSON.stringify(phLineSelectedAll));   
      }
      
    }


    const removePH = (index) => {
      let data = [...phLineSelectedAll];
      data.splice(index, 1)
      setPhLineSelectedAll(data)
      console.log('index:' + index);
    }
   
  return (
    <Box m="20px">
      <Header title="" subtitle="Daily Time Record" />     
      <Box m="40px 0 0 0"
        height="75vh">         
          <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>   
               <Grid container spacing={2}>                                      
                  <Grid item xs={4}>          
                    <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">PH Linue Number</InputLabel>      
                            <Select
                                fullWidth
                                name="phLineNoSelect"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={phLineNo}
                                required label="PH Linue Number"
                                onChange={phLineNoChangeSelect}
                              
                              >
                                {phLines}
                          </Select>
                      </FormControl>   
                  </Grid>      
                  <Grid item xs={4}>           
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="PH No."
                        value={phLineNoLine}                                        
                        name="phLN"
                        error={!!touched.brNumber && !!errors.brNumber}
                        helperText={touched.brNumber && errors.brNumber}
                        sx={{ gridColumn: "span 2" }}
                      />   
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                    <DatePicker  selected={dt} onChange={(date) => setDt(date)} />                                     
                  </Grid>
                          
                  
                  <Grid item xs={4}>  
                    <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Position</InputLabel>      
                            <Select
                                fullWidth
                                name="phLineNoSelect"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={position}
                                required label="Position"
                                onChange={positionChangeSelect}
                              
                              >
                                {positionData}
                          </Select>
                      </FormControl>                    
                  </Grid>   
                  <Grid item xs={4}>
                    <Button type="submit" color="secondary" variant="contained" fullWidth>
                      Save
                    </Button>

                  </Grid>              
              </Grid> 
              












              <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example"  textColor="secondary"
                    indicatorColor="secondary">                   
                      <Tab label="PH LINE ATTENDANCE" {...a11yProps(0)} />                      
                    </Tabs>
                  </Box>                  
                  <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2}>                      
                      <Grid item xs={10}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">PH Line Attendance</InputLabel>
                            <Select
                              name="phLineSelect"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={phLine}
                              required label="Truck Loader"
                              onChange={phLineChangeSelect}
                            
                            >
                              {phNames}
                            </Select>
                          </FormControl>   
                      </Grid>
                      <Grid item xs={2}>
                        <Button  color="secondary" variant="contained"  onClick={addPHLine}   fullWidth >
                          Add
                        </Button>
                        
                      </Grid> 
                    </Grid>  
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          {/*
                        <TableRow>
                            <TableCell align="right" colSpan={3}>
                              AM
                            </TableCell>
                            <TableCell align="right" colSpan={2}>PM</TableCell>
                          </TableRow>
                        */}
                          <TableRow>
                            <StyledTableCell>Names</StyledTableCell>
                            <StyledTableCell align="center">Boxes Allocation</StyledTableCell>   
                            <StyledTableCell align="center">IN AM</StyledTableCell>   
                            <StyledTableCell align="center">OUT AM</StyledTableCell>   
                            <StyledTableCell align="center">IN PM </StyledTableCell>   
                            <StyledTableCell align="center">OUT PM</StyledTableCell>   
                            <StyledTableCell align="center">OT/10 PM</StyledTableCell>   
                            <StyledTableCell align="center">10PM-6AM</StyledTableCell>   
                            <StyledTableCell align="center">Work Done/Operation</StyledTableCell>                                
                            <StyledTableCell align="center">Action</StyledTableCell>                     
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {phLineSelectedAll.map((row,index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField   fullWidth                              
                                  variant="filled"
                                  type="number"
                                  label="Boxes"
                                                         
                                  min="1"                             
                                  name="txtAllo"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />  
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                                              
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="In AM"                                                        
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtInAm"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />                        
                              </StyledTableCell>
                              <StyledTableCell align="center">
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="Out AM"                                                        
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtOutAm"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />      
                              </StyledTableCell>

                              <StyledTableCell align="center">
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="In PM"                                                       
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtInPm"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />      
                              </StyledTableCell>
                              <StyledTableCell align="center">
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="Out PM"                                                       
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtOutPm"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />     
                              </StyledTableCell>
                              <StyledTableCell align="center">
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="OT"                                                       
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtOT"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />   
                              </StyledTableCell>
                              <StyledTableCell align="center">
                              <TextField                                 
                                  variant="filled"
                                  type="time"
                                  label="Night"                                                       
                                  inputProps={{ 
                                    min: '9:00', 
                                    max: '16:00',
                                  }}                          
                                  name="txtNight"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />   
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField                                 
                                  variant="filled"
                                  type="text"
                                  label="Work Done"                                                                                                                
                                  name="txtWork"                          
                                  error={!!touched.line && !!errors.line}
                                  helperText={touched.line && errors.line}
                                  sx={{ gridColumn: "span 2" }}
                                />  
                              </StyledTableCell>                             
                              <StyledTableCell align="center">
                              <Button  color="error" variant="contained"  onClick={() => removePH(index)} >
                                  Remove
                                </Button>
                              </StyledTableCell>
                              
                                
                            
                            </StyledTableRow>
                          ))}
                          
                        </TableBody>                     
                      </Table>
                    </TableContainer>                   
                  </TabPanel>                 
              </Box>

           
              </form>
              
            )}
          
            
            </Formik>
          </Box>
      </Box>
    </Box>
  );
};


const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  brNumber: yup.string().required("required"),
  line: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),

  weekNo: yup.string().required("required"),
  blk: yup.string().required("required"),
  numBunches: yup.string().required("required"),
  arbSelected: yup.string().required("required"),
});
const initialValues = {
  brNumber: "",
  line: "", 
  blk: "",
  weekNo: "",
  numBunches: "",
  arbSelected:"",
};



export default DailyTimeRecord;
