import * as React from 'react';

import Typography from '@mui/material/Typography';



import { Box, Button, TextField,useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

import { mockDataBaggingSummary } from "../../data/mockData";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(name, lineNo, weekNo) {
  return { name, lineNo: lineNo, weekNo: weekNo };
}

const rows = [
  createData('Jon Snow', 'L-123/#16', 1),
  createData('Cersei Lannister', 'L-123/#16', 1),
  createData('Jaime Lannister', 'L-123/#16', 1),
  createData('Anya Stark', 'L-123/#16', 1),
  createData('Daenerys Targaryen', 'L-123/#16', 1),
  createData('Ever Melisandre', 'L-123/#16', 1),
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateBagging = () => {

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  

  return (
    
    <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Bagging Weekly
          </Typography>    
            
          <Box>
            <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                 
                <Box
                 
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                    <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name of ARB)</TableCell>
                          <TableCell align="right">Week No.</TableCell>
                          <TableCell align="right">Line No.</TableCell>
                        
                          <TableCell align="right">Bagged</TableCell>                
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.weekNo}</TableCell>       
                            <TableCell align="right">{row.lineNo}</TableCell>                      
                            <TableCell align="right">
                              <TextField
                                  fullWidth
                                  variant="filled"
                                  type="text"
                                  label="Enter Bagged Count"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={0}
                                  name="baggedCount"
                                  error={!!touched.firstName && !!errors.firstName}
                                  helperText={touched.firstName && errors.firstName}
                                  sx={{ gridColumn: "span 2" }}
                                />  
                            </TableCell>                                
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>                  
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button type="submit" color="secondary" variant="contained">
                    Save
                  </Button>
                </Box>
              </form>
            )}
            </Formik>
          </Box>
    
    </Box>
  );

  
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
};


export default CreateBagging;

