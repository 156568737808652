import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



import { Button} from "@mui/material";

import CreateBagging from '../../components/Modal/CreateBagging';
import CreateBunchRecept from '../../components/Modal/CreateBunchRecept';


const MenuTop = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [showBaggingModalOpen, setBaggingModalOpen] = React.useState(false);
  const [showBRModalOpen, setBRModalOpen] = React.useState(false);

  const toggleBaggingModal = () => {
      setBaggingModalOpen(true)  
      setAnchorEl(null);     
      
  }

  const toggleBRModal = () => {
    setBRModalOpen(true)  
    setAnchorEl(null);     
    
}


  
  return (
    <div>            
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="success"
      >
        Create Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={toggleBaggingModal}>Enter Weekly Bagging</MenuItem>
        <MenuItem onClick={toggleBRModal}>Enter Bunches Received</MenuItem>
        <MenuItem onClick={handleClose}>Enter Production Report</MenuItem>
        <MenuItem onClick={handleClose}>Create Delivery Receipt</MenuItem>
      </Menu>     
      <CreateBagging show={showBaggingModalOpen} close={() => setBaggingModalOpen(false)} />
      <CreateBunchRecept show={showBRModalOpen} close={() => setBRModalOpen(false)} />
    </div>
    
  );

  
};



export default MenuTop;

