import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Field, Form, Formik, FormikProps } from 'formik';

import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";

import { mockDataBaggingSummary } from "../../data/mockData";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import Header from "../../components/Header";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const EnterBunches = () => {

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const [arb, setArb] = React.useState('');
  const [harvester, setHarvester] = React.useState('');
  const [harvesterSelectedAll, setHarvesterSelectedAll] = React.useState([])

  const [truckLoader, setTruckLoader] = React.useState('');
  const [tlSelectedAll, setTLSelectedAll] = React.useState([])

  const [dt, setDt] = React.useState(new Date());



  const arbChangeSelect = (event) => {
    setArb(event.target.value);
  };

   const harvesterChangeSelect = (event) => {
    console.log('test harverster:',event.target.value);
    setHarvester(event.target.value);
   
  };

  const truckLoaderChangeSelect = (event) => {
    setTruckLoader(event.target.value);   
  };

  let arbNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
      return (    
        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
      )
    }, this);

   let harvesterNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
        //console.log('jona all id:' + item.id);
        //console.log('jona all name:' + item.name);
      return (    
        
        <MenuItem  key={i} value={item.id}>{item.name}</MenuItem >
      )
    }, this);

    let truckLoaderNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
        //console.log('jona all id:' + item.id);
        //console.log('jona all name:' + item.name);
      return (    
        
        <MenuItem  key={i} value={item.id}>{item.name}</MenuItem >
      )
    }, this);


  /*
    const [formFields, setFormFields] =  React.useState([
      { name: '', truckLoaders: '' }
    ])
    

    const handleFormChange = (event, index) => {
      let data = [...formFields];
      data[index][event.target.name] = event.target.value;
      setFormFields(data);
    }
    */
  
    const submit = (e) => {
      e.preventDefault();
      //console.log(formFields)
    }
  
   

    const addHarvesterFields = () => {
      
      console.log('me data itemCurrentv2:', harvester)
      if(harvester)
      {
        const data = mockDataBaggingSummary.find(({ id }) => id === harvester);
        const check = harvesterSelectedAll.find(({ id }) => id === harvester);
        if(!check)
        {
          let object = {
            id: data.id,
            name: data.name
          }
      
          setHarvesterSelectedAll([...harvesterSelectedAll, object])         
        }
        console.log('all index:', JSON.stringify(harvesterSelectedAll));   
      }
      
    }

    const addLoaderFields = () => {
      
      console.log('me data truckLoader:', truckLoader)
      if(truckLoader)
      {
        const data = mockDataBaggingSummary.find(({ id }) => id === truckLoader);
        const check = tlSelectedAll.find(({ id }) => id === truckLoader);
        if(!check)
        {
          let object = {
            id: data.id,
            name: data.name
          }
      
          setTLSelectedAll([...tlSelectedAll, object])         
        }
        console.log('all index:', JSON.stringify(tlSelectedAll));   
      }
      
    }
  
   

    const removeFieldsHarvester = (index) => {
      let data = [...harvesterSelectedAll];
      data.splice(index, 1)
      setHarvesterSelectedAll(data)
      console.log('index:' + index);
    }

    const removeFieldsLoader = (index) => {
      let data = [...tlSelectedAll];
      data.splice(index, 1)
      setTLSelectedAll(data)
      console.log('index:' + index);
    }
    
   
  return (
    <Box m="20px">
      <Header title="" subtitle="Enter Bunches Received" />     
      <Box m="40px 0 0 0"
        height="75vh">         
          <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                 
              <Box >          
                <Grid container spacing={2}>                
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">ARB Name</InputLabel>
                      <Select
                        name="arbSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={arb}
                        required label="ARB Name"
                        onChange={arbChangeSelect}
                      
                      >
                        {arbNames}
                      </Select>
                    </FormControl>      
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="BR Number"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="brNumber"
                        error={!!touched.brNumber && !!errors.brNumber}
                        helperText={touched.brNumber && errors.brNumber}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
               
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Line"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="line"
                        error={!!touched.line && !!errors.line}
                        helperText={touched.line && errors.line}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="BLK"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="blk"
                        error={!!touched.blk && !!errors.blk}
                        helperText={touched.blk && errors.blk}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Week Number"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="weekNo"
                        error={!!touched.weekNo && !!errors.weekNo}
                        helperText={touched.weekNo && errors.weekNo}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Number of Bunches"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="numBunches"
                        error={!!touched.numBunches && !!errors.numBunches}
                        helperText={touched.numBunches && errors.numBunches}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                    <DatePicker selected={dt} onChange={(date) => setDt(date)} />                                     
                  </Grid>
                 
                </Grid>  
                <Box sx={{my:3}}>
                  <Button type="submit" color="secondary" variant="contained">
                      Save
                    </Button>
                </Box>            

                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example"  textColor="secondary"
                    indicatorColor="secondary">
                      <Tab label="Harvester" {...a11yProps(0)} />
                      <Tab label="Truck loader" {...a11yProps(1)} />                      
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                      <Grid container spacing={2}>
                      <Grid item xs={10}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Harvester</InputLabel>
                            <Select
                              name="harvesterSelect"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={harvester}
                              required label="Harvester"
                              onChange={harvesterChangeSelect}
                            
                            >
                              {harvesterNames}
                            </Select>
                          </FormControl>   
                      </Grid>                     
                      <Grid item xs={2}>
                        <Button  color="secondary" variant="contained" onClick={addHarvesterFields}  fullWidth >
                          Add
                        </Button>
                        
                      </Grid> 
                    </Grid>  
                    {harvesterSelectedAll.map((form, index) => {
                        return (
                          <Grid container spacing={2} key={index}>                                              
                            <Grid item xs={8}>                        
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Harvester Name"
                                onBlur={handleBlur}
                                                     
                                name="harvesterName"
                                value = {form.name}
                                error={!!touched.line && !!errors.line}
                                helperText={touched.line && errors.line}
                                sx={{ gridColumn: "span 2" }}
                                InputProps={{
                                  readOnly: true
                                }}
                              />  
                            </Grid> 
                            <Grid item xs={2}>                        
                            <TextField
                                fullWidth
                                variant="filled"
                                type="number"
                                label="bunches"
                                onBlur={handleBlur}                          
                                min="1"                             
                                name="harvesterReceiptTotal"                          
                                error={!!touched.line && !!errors.line}
                                helperText={touched.line && errors.line}
                                sx={{ gridColumn: "span 2" }}
                              />  
                            </Grid>                                               
                            <Grid item xs={2}>
                              <Button  color="error" variant="contained" onClick={() => removeFieldsHarvester(index)}  fullWidth>
                                Remove
                              </Button>
                              
                            </Grid> 
                          </Grid>
                        )
                      })} 
                    
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2}>                      
                      <Grid item xs={10}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Truck Loader</InputLabel>
                            <Select
                              name="loaderSelect"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={truckLoader}
                              required label="Truck Loader"
                              onChange={truckLoaderChangeSelect}
                            
                            >
                              {truckLoaderNames}
                            </Select>
                          </FormControl>   
                      </Grid>
                      <Grid item xs={2}>
                        <Button  color="secondary" variant="contained" onClick={addLoaderFields} fullWidth >
                          Add
                        </Button>
                        
                      </Grid> 
                    </Grid>  
                    {tlSelectedAll.map((form, index) => {
                        return (
                          <Grid container spacing={2} key={index}>                                                                          
                            <Grid item xs={8}>                          
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Truck Loaders"
                                onBlur={handleBlur}
                               
                                value = {form.name}                    
                                name="truckLoaders"
                                error={!!touched.line && !!errors.line}
                                helperText={touched.line && errors.line}
                                sx={{ gridColumn: "span 2" }}
                                InputProps={{
                                  readOnly: true
                                }}
                              />  
                              
                            </Grid>    
                            <Grid item xs={2}>                        
                            <TextField
                                fullWidth
                                variant="filled"
                                type="number"
                                label="bunches"
                                onBlur={handleBlur}                          
                                min="1"                             
                                name="loaderReceiptTotal"                                                    
                                error={!!touched.line && !!errors.line}
                                helperText={touched.line && errors.line}
                                sx={{ gridColumn: "span 2" }}
                              />  
                            </Grid>                   
                            <Grid item xs={2}>
                              <Button  color="error" variant="contained" onClick={() => removeFieldsLoader(index)} fullWidth  >
                                Remove
                              </Button>
                              
                            </Grid> 
                          </Grid>
                        )
                      })}



                  </TabPanel>                 
                </Box>





                
                
             
                                        
              
              </Box>             
                       
              </form>
              
            )}
            </Formik>
          </Box>
        </Box>
    </Box>
  );
};


const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  brNumber: yup.string().required("required"),
  line: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),

  weekNo: yup.string().required("required"),
  blk: yup.string().required("required"),
  numBunches: yup.string().required("required"),
  arbSelected: yup.string().required("required"),
});
const initialValues = {
  brNumber: "",
  line: "", 
  blk: "",
  weekNo: "",
  numBunches: "",
  arbSelected:"",
};



export default EnterBunches;
