import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Field, Form, Formik, FormikProps } from 'formik';

import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";

import { mockDataBaggingSummary,items,unit } from "../../data/mockData";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import Header from "../../components/Header";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const DeliveryReceipt = () => {

  
  
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const [arb, setArb] = React.useState('');
  const [customer, setCustomer] = React.useState('');

  const [item, setItems] = React.useState('');

  
  const [itemsSelectedFields, setItemsSelectedFields] = React.useState([])
  const [itemCurrent, setItemCurrent] = React.useState('');

  const [unitsSelected, setUnitsSelected] = React.useState([])
  const [unitSelected, setUnitSelected] = React.useState('');

  const arbChangeSelect = (event) => {
    setArb(event.target.value);
  };


  const itemsChangeSelect = (event) => {
    setItems(event.target.value);      
    console.log('me index:', event.target.value);   
    
    //const data = items.find(({ id }) => id === event.target.value);
    //console.log('me data:', data);    
    setItemCurrent(event.target.value);
    console.log('me data itemCurrent:', itemCurrent)
    


  };

   const customerChangeSelect = (event) => {
    setCustomer(event.target.value);    
  };

  const unitChangeSelect = (event, i) => {
    console.log('unit index:', i + ' value:',event.target.value);
    
    setUnitSelected(event.target.value); 
    /*
    const data = unitsSelected.find(({ index }) => index === i);
    console.log('data:', JSON.stringify(data));   
    if(!data)
    {
      console.log('new units:');   
      let object = {
        index: i,
        val: event.target.value
      }       
      setUnitsSelected([...unitsSelected, object])
    }
    else 
    {
      console.log('existing units:');  
      let data = [...unitsSelected];
      data.splice(i, 1)
      setItemsSelectedFields(data)
      console.log('remove index:' + i);

      let object = {
        index: i,
        val: event.target.value
      }       
      setUnitsSelected([...unitsSelected, object])
     
    }
   */
    console.log('all units:', JSON.stringify(unitsSelected));   
  };

  const addFields = () => {
    console.log('me data itemCurrentv2:', itemCurrent)
    if(itemCurrent)
    {
      const data = items.find(({ id }) => id === itemCurrent);
      const check = itemsSelectedFields.find(({ id }) => id === itemCurrent);
      if(!check)
      {
        let object = {
          id: data.id,
          name: data.name
        }
    
        setItemsSelectedFields([...itemsSelectedFields, object])
        /*
        itemsSelectedFields.push({
            id: data.id,
            name: data.name,
        })
        */
      }
      console.log('all index:', JSON.stringify(itemsSelectedFields));   
    }
    
  }

  
  let arbNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
      return (    
        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
      )
    }, this);

   let customerNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
        //console.log('jona all id:' + item.id);
        //console.log('jona all name:' + item.name);
      return (    
        
        <MenuItem  key={i} value={item.id}>{item.name}</MenuItem >
      )
    }, this);

    let itemsAll = items.length > 0 && items.map((item, i) => {
    return (    
      <MenuItem  key={i} value={item.id}>{item.name}</MenuItem >
    )
  }, this);

  let units = unit.length > 0
  && unit.map((item, i) => {
  return (    
    <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
  )
}, this);

    const [formFields, setFormFields] =  React.useState([
      { name: '', truckLoaders: '' }
    ])

    const handleFormChange = (event, index) => {
      let data = [...formFields];
      data[index][event.target.name] = event.target.value;
      setFormFields(data);
    }
  
    const submit = (e) => {
      e.preventDefault();
      console.log(formFields)
    }
  
   
  
    const removeFields = (index) => {
      let data = [...itemsSelectedFields];
      data.splice(index, 1)
      setItemsSelectedFields(data)
      console.log('index:' + index);
    }
   
  return (
    <Box m="20px">
      <Header title="" subtitle="Delivery Receipt" />     
      <Box m="40px 0 0 0"
        height="75vh">         
          <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>   
               <Grid container spacing={2}>   
                  <Grid item xs={8}>                  
                  </Grid>    
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="DR Number"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="drNumber"
                        error={!!touched.brNumber && !!errors.brNumber}
                        helperText={touched.brNumber && errors.brNumber}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>

                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Customer Name</InputLabel>
                      <Select
                        name="customerSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customer}
                        required label="Customer Name"
                        onChange={customerChangeSelect}
                      
                      >
                        {customerNames}
                      </Select>
                    </FormControl>      
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="date"
                        label="Date"
                        onBlur={handleBlur}
                        onChange={handleChange}                                                       
                        name="txtDate"
                        error={!!touched.brNumber && !!errors.brNumber}
                        helperText={touched.brNumber && errors.brNumber}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>

                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">ARB Name</InputLabel>
                      <Select
                        name="arbSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={arb}
                        required label="ARB Name"
                        onChange={arbChangeSelect}
                      
                      >
                        {arbNames}
                      </Select>
                    </FormControl>      
                  </Grid>
                 



               
                  
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Week No"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="weekNo"
                        error={!!touched.blk && !!errors.blk}
                        helperText={touched.blk && errors.blk}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>

                  <Grid item xs={8}>
                    <FormControl fullWidth>                       
                    <Select
                        name="itemSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item}
                        required label="Items"
                        onChange={itemsChangeSelect}
                      
                      >
                        {itemsAll}
                      </Select>
                    </FormControl>      
                  </Grid>                               
                  
                  <Grid item xs={2}>
                    <Button  color="secondary" variant="contained" onClick={addFields} fullWidth >
                      Add
                    </Button>
                  </Grid>   
                  <Grid item xs={2}>
                    <Button type="submit" color="secondary" variant="contained" fullWidth>
                      Save
                    </Button>

                  </Grid>              
              </Grid> 
              
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Brand</StyledTableCell>
                      <StyledTableCell align="center">456H</StyledTableCell>
                      <StyledTableCell align="center">789H</StyledTableCell>
                      <StyledTableCell align="center">Qty</StyledTableCell>
                      <StyledTableCell align="center">Unit</StyledTableCell>
                      <StyledTableCell align="center">Cluster</StyledTableCell>                      
                      <StyledTableCell align="center">Total Boxes</StyledTableCell>
                      <StyledTableCell align="center">Remarks</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemsSelectedFields.map((row,index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        {row.protein}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        {row.protein}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        <TextField                            
                            variant="filled"
                            type="number"
                            label=""
                            onBlur={handleBlur}
                            min = {1}                       
                            name="txtQty"                           
                            error={!!touched.line && !!errors.line}
                            helperText={touched.line && errors.line}
                            style = {{width: 100}}                            
                           
                          />  
                          
                        </StyledTableCell>
                        <StyledTableCell align="center">
                                         
                          <Select 
                              name="unitSelect"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={unitSelected}
                              required label="Units"                              
                              onChange={event => unitChangeSelect(event, index)}
                            
                            >
                              {units}
                            </Select>                        
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.protein}</StyledTableCell>
                        <StyledTableCell align="center">{row.protein}</StyledTableCell>
                        <StyledTableCell align="center">
                        <TextField   
                            fullWidth                         
                            variant="filled"
                            type="text"
                            label="remarks"
                            onBlur={handleBlur}                                             
                            name="txtRemarks"                           
                            error={!!touched.line && !!errors.line}
                            helperText={touched.line && errors.line}
                           
                          />  

                         
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        <Button  color="error" variant="contained" onClick={() => removeFields(index)}  >
                            Remove
                          </Button>
                        </StyledTableCell>
                         
                          
                       
                      </StyledTableRow>
                    ))}
                    
                  </TableBody>
                 <TableFooter>
                        <TableRow>
                        <StyledTableCell align="left">Total Boxes</StyledTableCell>
                        <StyledTableCell align="center">0</StyledTableCell>
                        <StyledTableCell align="center">0</StyledTableCell>
                        <StyledTableCell align="center">3</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">50</StyledTableCell>
                        
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>
                 </TableFooter>
                </Table>
              </TableContainer>
                            
              </form>
              
            )}
          
            
            </Formik>
          </Box>
      </Box>
    </Box>
  );
};


const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  brNumber: yup.string().required("required"),
  line: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),

  weekNo: yup.string().required("required"),
  blk: yup.string().required("required"),
  numBunches: yup.string().required("required"),
  arbSelected: yup.string().required("required"),
});
const initialValues = {
  brNumber: "",
  line: "", 
  blk: "",
  weekNo: "",
  numBunches: "",
  arbSelected:"",
};



export default DeliveryReceipt;
