import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Field, Form, Formik, FormikProps } from 'formik';

import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";

import { mockDataBaggingSummary } from "../../data/mockData";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateBunchRecept = (props) => {
  //const modalState = props.toggle
  
  console.log("PROPS in MODAL", props);
  
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const [arb, setArb] = React.useState('');
   const [person, setPerson] = React.useState('');

  const arbChangeSelect = (event) => {
    setArb(event.target.value);
  };

   const harvesterChangeSelect = (event) => {
    setPerson(event.target.value);
    console.log('jona person:' + JSON.stringify(person))
    console.log('jona person text:' + JSON.stringify(event.target.TextField))
  };

  let arbNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
      return (    
        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
      )
    }, this);

   let harvesterNames = mockDataBaggingSummary.length > 0
    	&& mockDataBaggingSummary.map((item, i) => {
        //console.log('jona all id:' + item.id);
        //console.log('jona all name:' + item.name);
      return (    
        
        <MenuItem key={i} value={item.id + '-' + item.name}>{item.name}</MenuItem>
      )
    }, this);


  
    const [formFields, setFormFields] =  React.useState([
      { name: '', truckLoaders: '' }
    ])

    const handleFormChange = (event, index) => {
      let data = [...formFields];
      data[index][event.target.name] = event.target.value;
      setFormFields(data);
    }
  
    const submit = (e) => {
      e.preventDefault();
      console.log(formFields)
    }
  
    const addFields = () => {
      let object = {
        name: '',
        truckLoaders: ''
      }
  
      setFormFields([...formFields, object])
      console.log('jona:' + JSON.stringify(formFields))
    }
  
    const removeFields = (index) => {
      let data = [...formFields];
      data.splice(index, 1)
      setFormFields(data)
    }
  
  return (
    <Modal
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{mb:5}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Bunch Receipt
            </Typography>
          </Box>
          
          <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                 
              <Box >          
                <Grid container spacing={2}>                
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">ARB Name</InputLabel>
                      <Select
                        name="arbSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={arb}
                        required label="ARB Name"
                        onChange={arbChangeSelect}
                      
                      >
                        {arbNames}
                      </Select>
                    </FormControl>      
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="BR Number"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="brNumber"
                        error={!!touched.brNumber && !!errors.brNumber}
                        helperText={touched.brNumber && errors.brNumber}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
               
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Line"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="line"
                        error={!!touched.line && !!errors.line}
                        helperText={touched.line && errors.line}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="BLK"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="blk"
                        error={!!touched.blk && !!errors.blk}
                        helperText={touched.blk && errors.blk}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Week Number"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="weekNo"
                        error={!!touched.weekNo && !!errors.weekNo}
                        helperText={touched.weekNo && errors.weekNo}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Number of Bunches"
                        onBlur={handleBlur}
                        onChange={handleChange}   
                        min="1"                             
                        name="numBunches"
                        error={!!touched.numBunches && !!errors.numBunches}
                        helperText={touched.numBunches && errors.numBunches}
                        sx={{ gridColumn: "span 2" }}
                      />  
                  </Grid>
                </Grid>  
              <Box sx={{my:3}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Harvester Names
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                      <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Harvester</InputLabel>
                      <Select
                        name="personSelect"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={person}
                        required label="Harvester"
                        onChange={harvesterChangeSelect}
                      
                      >
                        {harvesterNames}
                      </Select>
                    </FormControl>   
                </Grid>
                <Grid item xs={2}>
                  <Button  color="secondary" variant="contained" onClick={addFields}  >
                    Add
                  </Button>
                  
                </Grid> 
              </Grid>  
                {formFields.map((form, index) => {
                    return (
                      <Grid container spacing={2} key={index}>                                              
                        <Grid item xs={5}>                        
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Harvester Name"
                            onBlur={handleBlur}
                            onChange={event => handleFormChange(event, index)}  
                            min="1"                             
                            name="harvesterName"
                            value = {form.name}
                            error={!!touched.line && !!errors.line}
                            helperText={touched.line && errors.line}
                            sx={{ gridColumn: "span 2" }}
                          />  
                        </Grid> 
                        <Grid item xs={5}>                          
                        <TextField
                            fullWidth
                            variant="filled"
                            type="number"
                            label="Truck Loaders"
                            onBlur={handleBlur}
                            onChange={event => handleFormChange(event, index)}  
                            min="1"                             
                            name="truckLoaders"
                            error={!!touched.line && !!errors.line}
                            helperText={touched.line && errors.line}
                            sx={{ gridColumn: "span 2" }}
                          />  
                          
                        </Grid>                      
                        <Grid item xs={2}>
                          <Button  color="error" variant="contained" onClick={() => removeFields(index)}  >
                            Remove
                          </Button>
                          
                        </Grid> 
                      </Grid>
                    )
                  })} 
                  
               
             
                                        
              
              </Box>
              <Box display="flex" justifyContent="end" mt="20px" >
                <Button  color="secondary" variant="contained" onClick={props.close}  >
                  Cancel
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  Save
                </Button>
              </Box>
              </form>
              
            )}
            </Formik>
          </Box>
        </Box>
      </Modal>
  );
};


const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  brNumber: yup.string().required("required"),
  line: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),

  weekNo: yup.string().required("required"),
  blk: yup.string().required("required"),
  numBunches: yup.string().required("required"),
  arbSelected: yup.string().required("required"),
});
const initialValues = {
  brNumber: "",
  line: "", 
  blk: "",
  weekNo: "",
  numBunches: "",
  arbSelected:"",
};



export default CreateBunchRecept;
